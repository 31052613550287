import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";
import { normalizeRole, RoleS } from "@ugg/shared/utils/role-helpers";

interface RoleSplitTypeProps extends FilterSelect {
  className?: string;
  role?: RoleS;
  options: FilterOption[];
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function RoleSplit(props: RoleSplitTypeProps) {
  const { t } = useTranslation();
  const { className, onFilterChange, filters, disableTooltip, options, role } = props;

  const displayRoleFilters = () => {
    const selectedRole = normalizeRole(role || filters.role);

    return options.map((option) => {
      const isSelectedRole = selectedRole === normalizeRole(option.value);

      const selectorClassNames = classnames("role-filter", {
        active: isSelectedRole,
      });

      return (
        <TooltipContainer key={option.value} tooltipInfo={t(option.label)} disableTooltip={disableTooltip}>
          <div className={selectorClassNames} onClick={() => onFilterChange("role", option)}>
            {isSelectedRole && option.imgActive ? option.imgActive : option.img}
          </div>
        </TooltipContainer>
      );
    });
  };

  return <div className={classnames("role-filter-container", className)}>{displayRoleFilters()}</div>;
}
