import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import { compile } from "path-to-regexp";
import _isEqual from "lodash.isequal";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";

import { FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

export const FILTER_IS_NOT_DEFAULT_CLASSNAME = "filter_not-default";

export interface FilterManagerHookResults {
  filters: Record<string, any>;
  onFilterChange: (filterType: string, filterOption: FilterOption) => void;
  isDefault: (filterKey: string) => boolean;
}

export function useFilterManager<T = Record<string, any>>(
  page: keyof T,
  validParams: T,
  options?: {
    pathParameters?: { [key: string]: string };
  },
): FilterManagerHookResults {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();

  const { pathParameters } = options || {};

  const validateQueryParams = useValidateQueryParams(validParams);

  const updateQueryParams = (newFilter: Record<string, any>, pathname?: string) => {
    if (pathname !== location.pathname || !_isEqual(queryParams, newFilter)) {
      history.replace({
        pathname: pathname || location.pathname,
        search: buildQueryParams(newFilter),
      });
    }
  };

  const onFilterChange = (filterType: string, filterValue: FilterOption) => {
    let newFilter: { [key: string]: any } = {};

    if (filterType && filterValue) {
      newFilter[filterType] = filterValue.value;
    }

    newFilter = { ...queryParams, ...newFilter };
    newFilter = validateQueryParams(page, newFilter);

    if (filterValue.reset) {
      filterValue.reset.forEach((param) => delete newFilter[param]);
    }

    if (filterType && filterValue?.path) {
      if (filterValue?.getValidParams) {
        newFilter = validateQueryParams(undefined, newFilter, false, filterValue.getValidParams());
      }
      const toPath = compile(filterValue.path, { encode: encodeURIComponent });
      if (filterValue?.pathParameters && !pathParameters) {
        console.error(
          `'pathParameters' object missing. Either set pathParameters to false in your filter options or provide pathParameters object`,
        );
      }

      const path = toPath(pathParameters || {});
      return updateQueryParams(newFilter, path);
    }

    updateQueryParams(newFilter);
  };

  const validPageParams: Record<string, any> = validParams[page] || {};
  const defaultParams: Record<string, any> = validPageParams.default || {};

  const validatedQueryParams = validateQueryParams(page, queryParams, true);
  const filters = {
    ...defaultParams,
    ...validatedQueryParams,
  };

  const isDefault = (filterKey: string) => {
    return (filters && filters[filterKey]) === (defaultParams && defaultParams[filterKey]);
  };

  return {
    filters,
    onFilterChange,
    isDefault,
  };
}

export function FilterManagerWrapper(props: { className?: string; children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div className={classNames("filter-manager", props.className)}>
      {/* <div className="filter-width-wrapper"> */}
      <div className="filter-manager_container">{props.children}</div>
      {/* </div> */}
    </div>
  );
}
