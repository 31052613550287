import { useState, useRef } from "react";
import classNames from "classnames";
import Select, { StylesConfig, SingleValue, MultiValue, GroupBase } from "react-select";
import FilterModal from "./FilterModal";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

interface DefaultSelectProps<T extends { value: string; label: string }> {
  className?: string;
  options: Array<T>;
  value: string;
  placeholder?: string;
  formatOptionLabel?: (option: T) => React.ReactNode;
  onChange: (option: T) => void;
  tabIndex?: number;
  isSearchable?: boolean;
  maxHeight?: number;
  width: number;
  fontSize?: number;
  onMenuClose?: () => void;
  isDisabled?: boolean;
  // Props for mobile view
  title?: string;
  group?: string;
  error?: boolean;
  isMobile?: boolean;
  customButton?: React.ReactNode;
}

export default function DefaultSelect<T extends { value: string; label: string }>(props: DefaultSelectProps<T>) {
  const {
    title,
    group,
    className,
    options,
    placeholder,
    onChange,
    value,
    tabIndex,
    isSearchable,
    maxHeight,
    width,
    formatOptionLabel,
    onMenuClose,
    isMobile,
    isDisabled,
    error,
    customButton,
    fontSize,
  } = props;

  const [optionToApply, setOptionToApply] = useState<T | null>(null);

  const onApplyChange = (option: T | null) => {
    if (option) {
      setOptionToApply(null);
      onChange(option);
    }
  };

  const findElement = (currentValue: string): (typeof options)[0] | undefined => {
    return (options || []).find((option) => option.value === currentValue);
  };

  const closeModal = () => {
    setOptionToApply(null);
  };

  const selectedOption = findElement(value);

  let customStyles: StylesConfig<T, boolean, GroupBase<T>> = {
    clearIndicator: () => ({}),
    container: () => ({}),
    control: () => ({}),
    dropdownIndicator: () => ({}),
    group: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    input: () => ({
      fontSize,
    }),
    loadingIndicator: () => ({}),
    loadingMessage: () => ({}),
    menuPortal: () => ({}),
    multiValue: () => ({}),
    multiValueLabel: () => ({}),
    multiValueRemove: () => ({}),
    noOptionsMessage: () => ({}),
    option: () => ({
      fontSize,
    }),
    placeholder: () => ({
      fontSize,
    }),
    singleValue: () => ({
      fontSize,
    }),
    valueContainer: () => ({
      fontSize,
    }),
    menu: (base) => ({
      maxHeight: maxHeight || 500,
      zIndex: 10,
      width: "auto",
      borderRadius: "4px",
      backgroundColor: "#25254b",
      overflow: "hidden",
      position: "absolute",
      top: "100%",
      marginBottom: "8px",
      marginTop: "8px",
      cursor: "pointer",
    }),
    menuList: (base) => ({
      width: width,
      minWidth: width,
      zIndex: 10,
      fontFamily: "Helvetica, sans-serif",
      maxHeight: maxHeight || 500,
      overflowY: "auto",
      paddingTop: "0px",
      paddingBottom: "0px",
    }),
  };

  if (error) {
    customStyles = {
      ...customStyles,
      control: (provided, state) => ({
        ...provided,
        borderColor: "red !important",
      }),
    };
  }

  const selectComponent = (
    <>
      {
        // Need this because clicks don't trigger on parent <div>
        isMobile && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}></div>
      }
      <Select
        placeholder={placeholder || "Select"}
        formatOptionLabel={formatOptionLabel}
        components={{ DropdownIndicator: () => <TriangleArrowDown className="custom-indicator" /> }}
        styles={customStyles}
        className={classNames("default-select", className)}
        classNamePrefix={`default-select`}
        options={options}
        // onChange={handleChange}
        onChange={(option) => {
          onApplyChange(option as T);
          return option;
        }}
        value={selectedOption}
        isSearchable={isSearchable}
        defaultValue={null}
        isClearable={false}
        onMenuClose={onMenuClose}
        isDisabled={isMobile || isDisabled}
        tabIndex={tabIndex}
        isMulti={false}
      />
    </>
  );

  return (
    <div style={{ position: "relative" }}>
      {isMobile ? (
        <FilterModal
          button={customButton || selectComponent}
          title={title}
          group={group}
          options={options}
          curValue={optionToApply || selectedOption}
          onApply={onApplyChange}
          onCancel={closeModal}
        />
      ) : (
        selectComponent
      )}
    </div>
  );
}
