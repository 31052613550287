import { useRef } from "react";
import classnames from "classnames";
import DefaultSelect from "../DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface RoleProps extends FilterSelect {
  className?: string;
  role?: string;
  options: FilterOption[];
  disableByQueueType?: boolean;
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function Role(props: RoleProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();

  const { className, role, options, filters, isDefault, onFilterChange, disableTooltip, isMobile } = props;

  const formatOptionLabel = ({ value, label, img, imgActive }: (typeof options)[number]) => (
    <div className="role-value">
      {img}
      <div style={{ marginLeft: "12px" }}>{t(label)}</div>
    </div>
  );

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Role")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Role")}
          group={"role"}
          isMobile={isMobile}
          className={classnames("filter-select", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]: !isDefault("role"),
          })}
          options={options}
          onChange={(selected: (typeof options)[number]) => {
            onFilterChange("role", selected);
          }}
          value={role || filters.role}
          formatOptionLabel={formatOptionLabel}
          width={120}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
