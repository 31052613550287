import { useRef } from "react";
import classnames from "classnames";
import DefaultSelect from "../DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface QueueTypeProps extends FilterSelect {
  className?: string;
  options: FilterOption[];
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function QueueType(props: QueueTypeProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();
  const { className, isMobile, options, filters, isDefault, onFilterChange, disableTooltip } = props;

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Queue Type")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Queue Type")}
          group={"queue-type"}
          isMobile={isMobile}
          className={classnames("filter-select queue-type", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]: !isDefault("queueType"),
          })}
          options={options}
          onChange={(selected: (typeof options)[number]) => {
            onFilterChange("queueType", selected);
          }}
          formatOptionLabel={(option: (typeof options)[number]) => <div>{t(option.label)}</div>}
          value={filters.queueType}
          width={150}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
